export const getData = () => {
  return fetch(`https://d76l5lqi1cha1.cloudfront.net/api/organizations`).then(
    (res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error('Something went wrong');
      }
    }
  );
};
