const Tooltip = ({ tooltipCoords, tooltipVisibility, description }) => {
  const style = {
    top: tooltipCoords.y,
    left: tooltipCoords.x,
    display: `${tooltipVisibility ? 'flex' : 'none'}`,
  };

  const { Address, Name, Phone, WorkTime } = description;

  return (
    <div className='tooltip' style={style}>
      <div className='tooltip__header'>
        <h4 className='tooltip__title'>{Name ? Name : 'Untitled'}</h4>
      </div>
      <div className='tooltip__body'>
        <div className='tooltip__box'>
          <p className='tooltip__subtitle'>Address:</p>
          <p className='tooltip__text'>{Address ? Address : '-'}</p>
        </div>
        {Phone && (
          <div className='tooltip__box'>
            <p className='tooltip__subtitle'>Phone:</p>
            <p className='tooltip__text'>{Phone}</p>
          </div>
        )}
        {WorkTime && (
          <div className='tooltip__box'>
            <p className='tooltip__subtitle'>Work time:</p>
            <p className='tooltip__text'>{WorkTime}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tooltip;
