import { useState, useEffect, useCallback, useRef } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { getData } from './api/api';

import Bar from './components/Bar';
import Map from './components/Map';
import Tooltip from './components/Tooltip/Tooltip';
import { getBrowserLocation } from './helpers/geolocation';

const defaultCenter = { lat: 52.828611, lng: 20.790222 };

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState();
  const [overlay, setOverlay] = useState(false);
  const [description, setDescription] = useState({});
  const [initialCenter, setInitialCenter] = useState(defaultCenter);
  const [tooltipCoords, setTooltipCoords] = useState({});
  const [tooltipVisibility, setTooltipVisibility] = useState(false);
  const [addPlace, setAddPlace] = useState(false);
  const [isIframeLoading, setIframeLoading] = useState(true);

  const [size, setSize] = useState({});
  const ref = useRef();
  const resizeHandler = () => {
    const {
      clientHeight = document.body.clientHeight,
      clientWidth = document.body.clientWidth,
    } = ref.current || {};
    setSize({ clientHeight, clientWidth });
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const json = await getData();
        setData(json.result);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    getBrowserLocation(defaultCenter)
      .then((currentLocation) => {
        setInitialCenter(currentLocation);
      })
      .catch((defaultLocation) => {
        setInitialCenter(defaultLocation);
      });
  }, []);

  const onSelectChange = useCallback((coordinates) => {
    setInitialCenter(coordinates);
  }, []);

  if (error) {
    return (
      <div className='app loader'>
        <h1>ERROR: {error.message}</h1>
        <h3>Please reload page</h3>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className='app loader'>
        <ThreeDots color='#1BB876' height={100} width={100} />
      </div>
    );
  }

  const closeHandler = () => {
    setOverlay(false);
    setAddPlace(false);
    setIframeLoading(true);
  };

  const addPlaceHandler = (e) => {
    e.preventDefault();
    setOverlay(true);
    setAddPlace(true);
  };

  const iframeLoadingHandler = () => {
    setIframeLoading(false);
  };

  return (
    <div className='app wrapper' ref={ref}>
      <Bar
        description={description}
        data={data}
        onSelectChange={onSelectChange}
        clientWidth={size.clientWidth}
        defaultCenter={defaultCenter}
        overlay={overlay}
        addPlace={addPlace}
        closeHandler={closeHandler}
        addPlaceHandler={addPlaceHandler}
        iframeLoadingHandler={iframeLoadingHandler}
        isIframeLoading={isIframeLoading}
      />
      <Map
        data={data}
        setOverlay={setOverlay}
        setDescription={setDescription}
        setTooltipCoords={setTooltipCoords}
        setTooltipVisibility={setTooltipVisibility}
        overlay={overlay}
        initialCenter={initialCenter}
        clientWidth={size.clientWidth}
        closeHandler={closeHandler}
      />
      {description && (
        <Tooltip
          tooltipCoords={tooltipCoords}
          tooltipVisibility={tooltipVisibility}
          description={description}
        />
      )}
    </div>
  );
}

export default App;
