import { ThreeDots } from 'react-loader-spinner';
import Description from '../Description/Description';
import Footer from '../Footer';
import Header from '../Header';
import Search from '../Search';

const Bar = ({
  description,
  data,
  onSelectChange,
  clientWidth,
  defaultCenter,
  overlay,
  addPlace,
  closeHandler,
  addPlaceHandler,
  aboutMapHandler,
  isIframeLoading,
  iframeLoadingHandler,
}) => {
  const sidebarClasses = `sidebar ${overlay && 'sidebar_description'}`;

  return clientWidth > 768 ? (
    <aside className={sidebarClasses}>
      <Header overlay={overlay} closeHandler={closeHandler} />
      <section className='section'>
        {addPlace && overlay && (
          <>
            {isIframeLoading && (
              <div className='loader'>
                <ThreeDots color='#1BB876' height={80} width={80} />
              </div>
            )}
            <iframe
              className='iframe'
              id='test'
              seamless
              frameBorder='0'
              onLoad={iframeLoadingHandler}
              src='//airtable.com/embed/shrqHw4jHd9BSrezX'
            ></iframe>
          </>
        )}
        {!addPlace && (
          <>
            <Search
              data={data}
              onSelectChange={onSelectChange}
              defaultCenter={defaultCenter}
            />
            <div className='scroll'>
              <div className='section__info'>
                {description && overlay ? (
                  <Description description={description} />
                ) : (
                  <div className='about'>
                    <p>
                      This Volunteer initiative helps everyone who needs to find
                      the right place for a remote job.
                    </p>
                    <p>
                      Help us to add or monitor of coworking spaces in your town
                      or country by using simple form bellow.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {!addPlace && (
          <Footer
            addPlaceHandler={addPlaceHandler}
            aboutMapHandler={aboutMapHandler}
          />
        )}
      </section>
    </aside>
  ) : (
    <>
      {overlay ? (
        <div className='mobile-bar'>
          <section className='section section_top'>
            <Header overlay={overlay} closeHandler={closeHandler} />
            {!addPlace && (
              <Search
                data={data}
                onSelectChange={onSelectChange}
                overlay={overlay}
                defaultCenter={defaultCenter}
              />
            )}
          </section>
          {!addPlace && description && (
            <section className='section section_center'>
              <div className='scroll'>
                <div className='section__info'>
                  <Description description={description} />
                </div>
              </div>
            </section>
          )}

          {addPlace && overlay && (
            <>
              {isIframeLoading && (
                <div className='loader'>
                  <ThreeDots color='#1BB876' height={80} width={80} />
                </div>
              )}
              <iframe
                className='iframe'
                id='test'
                seamless
                frameBorder='0'
                onLoad={iframeLoadingHandler}
                src='//airtable.com/embed/shrqHw4jHd9BSrezX'
              ></iframe>
            </>
          )}

          {!addPlace && (
            <section className='section section_bottom'>
              <Footer
                addPlaceHandler={addPlaceHandler}
                aboutMapHandler={aboutMapHandler}
              />
            </section>
          )}
        </div>
      ) : (
        <>
          <section className='section section_top'>
            <Header overlay={overlay} closeHandler={closeHandler} />
            <Search
              data={data}
              onSelectChange={onSelectChange}
              overlay={overlay}
              defaultCenter={defaultCenter}
            />
          </section>
          {!addPlace && (
            <section className='section section_bottom'>
              <Footer
                addPlaceHandler={addPlaceHandler}
                aboutMapHandler={aboutMapHandler}
              />
            </section>
          )}
        </>
      )}
    </>
  );
};

export default Bar;
