const Description = ({ description }) => {
  // data array contains {Address, Category, Country,	Email, Image,	Lat, Lng,	Name,	Phone, Published,	Website, WorkTime, SocialNetworks, icon, id,}
  const {
    Address,
    Category,
    Email,
    Image,
    Name,
    Phone,
    Website,
    WorkTime,
    SocialNetworks,
  } = description;

	let regexp = /^(?:http:\/\/|www\.|https:\/\/)([^\/]+)/gi; // eslint-disable-line

  const getDomain = (link) => {
    const matchedLink =
      link.match(regexp) === null || link.match(regexp).length === 0
        ? link
        : link.match(regexp)[0];
    return link ? matchedLink : false;
  };

  const getNetwork = (link) => {
    const socials = link.split(' ');
    return socials
      ? socials.map((social, index) => {
          if (social.includes('inst')) {
            return (
              <a
                key={index}
                rel='noopener noreferrer'
                href={social}
                target='_blank'
                className='description__weblink description__weblink_iconed description__weblink_instagram'
              >
                instagram
              </a>
            );
          }
          if (social.includes('face')) {
            return (
              <a
                key={index}
                rel='noopener noreferrer'
                href={social}
                target='_blank'
                className='description__weblink description__weblink_iconed description__weblink_facebook'
              >
                facebook
              </a>
            );
          }
          return false;
        })
      : false;
  };

  const getImages = (arr) => {
    return arr.length
      ? arr.map((item, index) => (
          <img
            className='description__image'
            key={index}
            src={item.url}
            alt='place image'
          />
        ))
      : false;
  };

  return (
    <div className='description'>
      <h3 className='description__title'>More info about</h3>

      <div className='description__box'>
        {Category && <span className='description__subtext'>{Category}</span>}
        {Name && <h3 className='description__name'>{Name}</h3>}
      </div>

      <div className='description__box'>
        <div className='description__line'></div>
      </div>

      {Address && (
        <div className='description__box'>
          <h4 className='description__subtitle'>Address:</h4>
          <span className='description__subtext'>{Address}</span>
        </div>
      )}

      {Phone && (
        <div className='description__box'>
          <h4 className='description__subtitle'>Phone:</h4>
          <span className='description__subtext'>{Phone}</span>
        </div>
      )}

      {Email && (
        <div className='description__box'>
          <h4 className='description__subtitle'>Email:</h4>
          <a href={`mailto:${Email}`} className='description__weblink'>
            {Email}
          </a>
        </div>
      )}

      {WorkTime && (
        <div className='description__box'>
          <h4 className='description__subtitle'>Work Time:</h4>
          <span className='description__subtext'>{WorkTime}</span>
        </div>
      )}

      {(Website || SocialNetworks) && (
        <div className='description__box'>
          <h4 className='description__subtitle'>Web:</h4>
          <div className='description__webbox'>
            {Website && (
              <a
                key='website'
                rel='noopener noreferrer'
                className='description__weblink description__weblink_iconed description__weblink_web'
                href={Website}
                target='_blank'
              >
                {getDomain(Website)}
              </a>
            )}
            {SocialNetworks && getNetwork(SocialNetworks)}
          </div>
        </div>
      )}

      {Image && <div className='description__box'>{getImages(Image)}</div>}
    </div>
  );
};

export default Description;
