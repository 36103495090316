import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className='wrapper'>
      <div className='page'>
        <section className='header'>
          <Link to='/' className='logo'>
            <img className='logo__img' src='./workplacehere.svg' alt='logo' />
          </Link>
        </section>

        <section className='main main_404'>
          <h1 className='page__title page__title_404'>oops...</h1>
          <h2 className='page__description page__description_404'>
            page not found
          </h2>
          <Link to='/' className='btn btn_green'>
            Go back to the home page
          </Link>
        </section>
      </div>
    </div>
  );
};

export default PageNotFound;
