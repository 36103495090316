const Footer = ({ addPlaceHandler }) => {
  return (
    <footer className='footer'>
      <a href='/' className='link' onClick={(e) => addPlaceHandler(e)}>
        Add a place to the map
      </a>
    </footer>
  );
};

export default Footer;
