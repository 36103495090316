import { Link } from 'react-router-dom';

const Header = ({ overlay, closeHandler }) => {
  return (
    <header className='header'>
      <Link to='/' className='logo'>
        <img className='logo__img' src='./workplacehere.svg' alt='logo' />
      </Link>
      {overlay && (
        <button className='close' onClick={() => closeHandler()}></button>
      )}
    </header>
  );
};

export default Header;
