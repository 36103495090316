import { useEffect, useState } from 'react';

const Search = ({ data, onSelectChange, overlay, defaultCenter }) => {
  const [countryListArr, setCountryListArr] = useState([]);

  useEffect(() => {
    const countryList = data.reduce((acc, current) => {
      const { Country } = current;
      if (!acc[Country]) {
        acc[Country] = current;
      }
      return acc;
    }, {});

    setCountryListArr(Object.values(countryList));
  }, []);

  const onChange = (e) => {
    const coord = JSON.parse(e.target.value);
    onSelectChange({ lat: Number(coord.lat), lng: Number(coord.lng) });
  };

  return (
    <div className={`search ${overlay ? 'white' : 'transparent'}`}>
      <select type='text' className='search__select' onChange={onChange}>
        <option value={JSON.stringify(defaultCenter)}>Countries</option>
        {countryListArr.map((item, index) => (
          <option
            key={index}
            value={JSON.stringify({ lat: item.Lat, lng: item.Lng })}
          >
            {item.Country}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Search;
