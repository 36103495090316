import { useCallback, useRef, memo } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  MarkerClusterer,
  Marker,
} from '@react-google-maps/api';

const API_KEY = 'AIzaSyDKKY9XbxFy08BkyuAZyQmjskyqLsm5zek'; //AWClub
const containerStyle = {
  display: 'flex',
  width: '100%',
};

const createKey = (location) => {
  return +location.Lat + +location.Lng;
};

const getPosition = (location) => {
  return { lat: Number(location.Lat), lng: Number(location.Lng) };
};

const icons = {
  cafe: './icons/cafe.svg',
  'cafe-coworking': './icons/coffeeshop.svg',
  coworking: './icons/coworking.svg',
};

const getIcon = (icons, location) => {
  return icons[location.Category ? location.Category.toLowerCase() : 'cafe'];
};

const defaultOptions = {
  panControl: true,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  clickableIcons: false,
  keyboardShortcuts: false,
  scrollwheel: true,
  disableDoubleClickZoom: false,
  fullscreenControl: false,
};

const Map = ({
  data,
  setOverlay,
  setDescription,
  overlay,
  initialCenter,
  clientWidth,
  setTooltipCoords,
  setTooltipVisibility,
  closeHandler,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY,
  });

  const mapRef = useRef(undefined);
  const onLoad = useCallback(function callback(map) {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback(function callback() {
    mapRef.current = undefined;
  }, []);

  const openDescription = (description) => {
    setOverlay(true);
    setDescription(description);
  };

  const showTooltip = (e, description) => {
    setTooltipCoords({ x: e.domEvent.x + 10, y: e.domEvent.y + 14 });
    setTooltipVisibility(true);
    setDescription(description);
  };

  const hideTooltip = () => {
    setTooltipVisibility(false);
  };

  return isLoaded ? (
    <div className='map'>
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={7}
        center={initialCenter}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={defaultOptions}
      >
        <MarkerClusterer maxZoom={12}>
          {(clusterer) =>
            data.map((location) => {
              return (
                <Marker
                  icon={getIcon(icons, location)}
                  key={createKey(location)}
                  position={getPosition(location)}
                  clusterer={clusterer}
                  clickable={true}
                  onClick={() => openDescription(location)}
                  onMouseOver={(e) => showTooltip(e, location)}
                  onMouseOut={() => hideTooltip()}
                />
              );
            })
          }
        </MarkerClusterer>
      </GoogleMap>
      {clientWidth > 768 ? (
        <div
          className={overlay ? 'overlay active' : 'overlay'}
          onClick={() => closeHandler()}
        ></div>
      ) : (
        false
      )}
    </div>
  ) : (
    <></>
  );
};

export default memo(Map);
